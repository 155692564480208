<template>
  <div class="container">
    <el-form :inline="true">
      <el-form-item label="课程名称">
          <el-input v-model="title" placeholder="名称"></el-input>
        </el-form-item>
        <el-form-item label="机构">
          <mechanism-select :mecid.sync="mecid"></mechanism-select>
        </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="getList(1)">搜索</el-button>
        <el-button type="success" @click="addActivityCourse"
          >添加课程</el-button
        >
      </el-form-item>
    </el-form>
    <div class="flex count">
        <p class="">总数{{ Total }}</p>
        <p class="m-l20">已选{{ muti_ids.length }}</p>
      </div>

    <auto-table
      :DataList="DataList"
      :option="Option"
      :total="Total"
      @changePage="getList"
      @selectionChange="selectionChange"
      rowKey="id"
      ref="atb"
    >
      <template #activity_time="{ row }">
        <div>
          <p>开始:{{ row.start_time }}</p>
          <p>结束:{{ row.end_time }}</p>
        </div>
      </template>
      <template #status="{ row }">
        <div>
          <el-tag type="info" size="small" v-if="row.status === 1">下架</el-tag>
          <el-tag type="success" size="small" v-if="row.status === 2"
            >上架中</el-tag
          >
        </div>
      </template>
      <template #handler="{ row }">
        <div>
          <el-button type="primary" size="small" @click="updateInfo(row)"
            >编辑</el-button
          >
          <el-button
            v-if="row.status == 1"
            type="success"
            size="small"
            @click="updateStatus(row, 2)"
            >上架</el-button
          >
          <el-button
            v-if="row.status == 2"
            type="warning"
            size="small"
            @click="updateStatus(row, 1)"
            >下架</el-button
          >
          <el-button type="danger" size="small" @click="del(row)"
            >删除</el-button
          >
        </div>
      </template>
    </auto-table>
    <div>
      <el-button type="warning" @click="delData('all')" :loading="loading"
        >取消全部课程</el-button
      >
      <el-button type="warning" :loading="loading" @click="delData('ids')"
        >取消选中的数据</el-button
      >
    </div>
    <addActivityCourse ref="add" @success="getList(1)"></addActivityCourse>
  </div>
</template>

<script>
import addActivityCourse from "./components/addActivityCourse.vue";

export default {
  components: {
    addActivityCourse,
  },
  data() {
    return {
      DataList: [],
      Option: [
        { name: "", value: "", col_type: "selection", reserveSelection: true },
        { name: "名称", value: "title" },
        { name: "价格", value: "discount_amout" },
        { name: "课节数", value: "course_num" },
        { name: "类目", value: "categories_child" },
        { name: "活动", value: "activity_tag" },
      ],
      Total: 0,
      status: 2,
      aid: "",
      tag: "",
      muti_ids: "",
      loading: false,
      title:'',
      mecid:''
    };
  },

  mounted() {
    this.aid = this.$route.query.aid;
    this.tag = this.$route.query.tag;
    this.getList(1);
  },

  methods: {
    addActivityCourse() {
      this.$refs.add.open({ activity_id: this.aid, activity_tag: this.tag });
    },
    //取消活动数据
    delData(type) {
      let msg = "";
      let data = {
        status: 2,
        type: "mechanism_offline",
        categories: "体育运动",
        activity_id: 0,
        activity_tag: "",
      };

      if (type == "all") {
        data.change_activity_id = this.aid
        msg = `确认取消${this.Total}门课程参加活动？`;
      } else {
        if (this.muti_ids.length == 0) {
          this.$message("请选择课程");
          return;
        }
        data.update_ids = this.muti_ids;
        msg = `确认取消${this.muti_ids.length}门课程参加活动？`;
      }

      this.$confirm(msg).then(() => {
        this.loading = true;
        this.$axios({
          url: "/user/masterSetPrice/batchUpdateActivity",
          data: data,
          method: "post",
        }).then((res) => {
          this.loading = false;
          this.$message("取消成功");
         this.getList(1)
        });
      });
    },
    selectionChange(v) {
      this.muti_ids = v.map((t) => t.id);
    },
    //获取列表
    getList(v) {
      let page = v || 1;
      this.$axios({
        url: "/user/masterSetPrice/queryManagerListPage",
        params: {
          currentPage: page,
          pageSize: 10,
          status: this.status,
          activity_id: this.aid,
          title:this.title || null,
          mechanism_id:this.mecid || null
        },
      }).then((res) => {
        this.DataList = res.data.data.rows;
        this.Total = res.data.data.total;
      });
    },
  },
};
</script>

<style lang="less" scoped>
.count {
  padding: 10px 30px;
  font-size: 16px;
}
</style>