<template>
  <!-- 代理商新增，修改 -->
  <div>
    <el-dialog
      title="添加参加活动的课程"
      :visible.sync="dialogVisible"
      width="70%"
    >
      <el-form label-width="120px" ref="form" :inline="true">
        <el-form-item label="课程名称">
          <el-input v-model="title" placeholder="名称"></el-input>
        </el-form-item>
        <el-form-item label="机构">
          <mechanism-select :mecid.sync="mecid"></mechanism-select>
        </el-form-item>
        <el-form-item label="城市">
          <citySelect @change="changeCity" checkStrictly></citySelect>
        </el-form-item>
        <el-form-item label="教学类目">
          <el-select
            v-model="categories"
            clearable
            name="categories_child"
            remote
            style="width: 150px"
          >
            <el-option
              v-for="item in listPageChild"
              :key="item.id"
              :label="item.name"
              :value="item.name"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="">
          <el-button type="primary" @click="search">搜索</el-button>
        </el-form-item>
      </el-form>

      <div class="flex count">
        <p class="">筛选总数{{ Total }}</p>
        <p class="m-l20">已选{{ muti_ids.length }}</p>
      </div>

      <auto-table
        ref="atb"
        :DataList="DataList"
        :option="Option"
        :total="Total"
        @changePage="getCourseList"
        @selectionChange="selectionChange"
        rowKey="id"
      >
        <template #activity_time="{ row }">
          <div>
            <p>开始:{{ row.start_time }}</p>
            <p>结束:{{ row.end_time }}</p>
          </div>
        </template>
        <template #handler="{ row }">
          <div>
            <el-button type="primary" size="small" @click="changeCourse(row)"
              >选择</el-button
            >
          </div>
        </template>
      </auto-table>

      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="addData('all')" :loading="loading"
          >添加筛选的全部数据</el-button
        >
        <el-button type="primary" :loading="loading" @click="addData('ids')"
          >添加选中的数据</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
import citySelect from "@/components/select/citySelect.vue";
import { ObjectChangedValue } from "@/js/utils.js";
import cooperatorSelect from "../../../components/select/cooperatorSelect.vue";
export default {
  components: {
    citySelect,
    cooperatorSelect,
  },
  data() {
    return {
      clear: false,
      form: {},
      temp: {},
      dialogVisible: false,
      loading: false,
      categories: "",
      cityList: [],
      cityoptions: [],
      cityProps: {
        checkStrictly: true,
        value: "district",
        label: "district",
        children: "childrens",
      },
      activityList: [],
      DataList: [],
      Option: [
        { name: "", value: "", col_type: "selection", reserveSelection: true },
        { name: "名称", value: "title" },
        { name: "价格", value: "discount_amout" },
        { name: "课节数", value: "course_num" },
        { name: "类目", value: "categories_child" },
      ],
      Total: 0,
      key: "",
      curCourse: {},
      listPageChild: [],
      mecid: "",
      title: "",
      muti_ids: [],
    };
  },

  mounted() {
    this.GetListPageChild();
  },

  methods: {
    //添加活动数据
    addData(type) {
      let msg = "";
      let data = {
        status: 2,
        type: "mechanism_offline",
        categories: "体育运动",
        activity_id:this.form.activity_id || 0,
         activity_tag:this.form.activity_tag || '',
      };

      if (type == "all") {
        if (this.title) {
          data.title = this.title;
        }
        if (this.cityList.length > 0) {
          data.province = this.cityList[0];
          data.city = this.cityList[1] || null;
          data.district = this.cityList[2] || null;
        }
        if (this.mecid) {
          data.mechanism_ids = this.mecid;
        }
        if (this.categories) {
          data.categories_child = this.categories;
        }
        msg = `确认添加${this.Total}门课程到${this.form.activity_tag }活动中？`
        if(Object.keys(data).length <= 5){
            this.$message('请选择筛选条件')
            return
        }
      } else {
        if(this.muti_ids.length == 0){
            this.$message('请选择课程')
            return
        }
        data.update_ids = this.muti_ids;
        msg = `确认添加${this.muti_ids.length}门课程到${this.form.activity_tag }活动中？`
      }

      this.$confirm(msg).then(() => {
        this.loading = true;
        this.$axios({
          url: "/user/masterSetPrice/batchUpdateActivity",
          data: data,
          method: "post",
        }).then((res) => {
          this.loading = false;
          this.dialogVisible = false;
          this.$message('添加成功')
          this.$emit("success",true)
        });
      });
    },
    selectionChange(v) {
      this.muti_ids = v.map((t) => t.id);
    },
    changeCity(v) {
      this.cityList = v;
    },
    GetListPageChild() {
      this.$axios({
        url: "/user/mechanismCategory/queryListPageChild",
        params: {
          type: 2,
          status: 2,
          source: "课程",
        },
      }).then((res) => {
        this.listPageChild = res.data.data;
      });
    },
    changeCourse(row) {
      this.curCourse = row;
      this.form.pic = row.face_url;
      this.temp.pic = row.face_url;
      this.form.amount = row.discount_amout;
      this.DataList = [];
    },
    search() {
      this.$refs.atb.clearAllSelection();
      this.getCourseList(1);
    },
    //获取列表
    getCourseList(v) {
      let page = v || 1;
      let data = {
        currentPage: page,
        pageSize: 10,
        status: 2,
        type: "mechanism_offline",
        categories: "体育运动",
      };
      if (this.title) {
        data.title = this.title;
      }
      if (this.cityList.length > 0) {
        data.province = this.cityList[0];
        data.city = this.cityList[1] || null;
        data.district = this.cityList[2] || null;
      }
      if (this.mecid) {
        data.mechanism_id = this.mecid;
      }
      if (this.categories) {
        data.categories_child = this.categories;
      }

      this.$axios({
        url: "/user/masterSetPrice/queryManagerListPage",
        params: data,
      }).then((res) => {
        this.DataList = res.data.data.rows;
        this.Total = res.data.data.total;
      });
    },
    //活动
    getActivityList() {
      this.$axios({
        url: "/user/businessActivityType/queryListPage",
        params: {
          currentPage: 1,
          pageSize: 20,
          status: 2,
        },
      }).then((res) => {
        this.activityList = res.data.data;
      });
    },
    addForm() {
      if (this.form.type == "course") {
        this.form.course_id = this.curCourse.id || null;
      }
      this.loading = true;
      this.$axios({
        url: "/user/userGift/insert",
        method: "post",
        data: this.form,
      })
        .then((res) => {
          this.$notify({
            message: "新增成功",
            duration: 2000,
            type: "success",
          });
          this.dialogVisible = false;
          this.loading = false;
          this.$emit("success", 1);
        })
        .catch(() => {
          this.loading = false;
        });
    },

    updateForm() {
      if (this.form.type == "course") {
        this.form.course_id = this.curCourse.id || null;
      }

      const data = ObjectChangedValue(this.temp, this.form);
      if (Object.keys(data).length == 0) {
        this.$message("没有修改数据，无需更新");
        return;
      }
      this.loading = true;
      this.$axios({
        url: "/user/userGift/update",
        method: "post",
        data: {
          id: this.form.id,
          ...data,
        },
      })
        .then((res) => {
          this.$notify({
            message: "更新成功",
            duration: 2000,
            type: "success",
          });
          this.dialogVisible = false;
          this.loading = false;
          this.$emit("success", 1);
        })
        .catch(() => {
          this.loading = false;
        });
    },

    open(info) {
      this.form = info;
      this.dialogVisible = true;
    },
  },
};
</script>

<style lang="less" scoped>
.count {
  padding: 10px 30px;
  font-size: 16px;
}
</style>